import _Page1 from "./page1";
import _FormPage from "./formPage";
import _Page14 from "./page14";
import _Page15 from "./page15";
import _Quest1 from "./quest1";
import _Quest2 from "./quest2";
import _Quest3 from "./quest3";
import _Quest4 from "./quest4";
import _Quest5 from "./quest5";

export const Page1 = _Page1;
export const Page14 = _Page14;
export const Page15 = _Page15;
export const FormPage = _FormPage;
export const Quest1 = _Quest1;
export const Quest2 = _Quest2;
export const Quest3 = _Quest3;
export const Quest4 = _Quest4;
export const Quest5 = _Quest5;
